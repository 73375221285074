/***
Bootstrap Time Picker
***/
.bootstrap-timepicker-widget table td a  {
  padding: 4px 0;
}

.bootstrap-timepicker-widget input,
.bootstrap-timepicker-widget input:focus {
  outline: none !important;
  border: 0;
}

.modal  .bootstrap-timepicker-widget {
  z-index: 10055 !important;
}

.bootstrap-timepicker-widget.timepicker-orient-bottom:before,
.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
  top: auto;
}