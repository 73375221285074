/***
jQuery Slimscroll
***/

.scroller {
  padding: 0px ;
  margin: 0px ;
  padding-right: 12px ;
  overflow: hidden;
}

.scroller-footer {
  margin-top: 10px;

  @include clearfix();
}

.portlet-body .slimScrollBar {
  margin-right: 0px ;
}