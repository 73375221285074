/*!
 * Tab drop for Bootstrap
 */

.tabbable-tabdrop {
	.nav-tabs,
	.nav-pills {
  		position: relative;

  		.caret {
  			display: none;      
  		}

  		.tabdrop .dropdown-toggle i {
  			font-size: 14px;     
  		}
	}

	.dropdown-menu {
		&:after {
			right: 10px;
			left: auto;
		}

		&:before {
			right: 9px;
			left: auto;
		}
	}
}
