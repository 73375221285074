/***
Bootstrap Editable
***/

.editable-input table,
.editable-input table th,
.editable-input table td,
.editable-input table tr {
  border: 0 !important;
}

.editable-input .combodate select {
  margin-bottom: 5px;
}