/***
Clockface
***/

.modal-open .clockface {
  z-index: 10055 !important;
}

.clockface .cell .inner.active,
.clockface .cell .outer.active {
  background-color:#4b8df8 !important;
  background-image: none ;
  filter:none ;
}