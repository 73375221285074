/***
Jansy File Input plugin css changes
***/

.fileinput {
  margin-bottom: 0;

  .close {
    float: none;
  }

  .input-group {
  	white-space:nowrap;   
  	overflow: hidden;
  }
}

