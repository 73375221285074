@import 'variables';
@import 'bootstrap-override';

@import 'components/mixins';

@import 'plugins/bootstrap-colorpicker';
@import 'plugins/bootstrap-datepaginator';
@import 'plugins/bootstrap-datepicker';
@import 'plugins/bootstrap-daterangepicker';
@import 'plugins/bootstrap-datetimepicker';
@import 'plugins/bootstrap-editable';
@import 'plugins/bootstrap-fileinput';
@import 'plugins/bootstrap-markdown';
@import 'plugins/bootstrap-modal';
@import 'plugins/bootstrap-select';
@import 'plugins/bootstrap-switch';
@import 'plugins/bootstrap-timepicker';
@import 'plugins/bootstrap-toastr';
@import 'plugins/bootstrap-wizard';
@import 'plugins/bootstrap-tabdrop';
@import 'plugins/bootstrap-wysihtml5';
@import 'plugins/bootstrap-summercode';
@import 'plugins/bootstrap-gtreetable';
@import 'plugins/bootstrap-tagsinput';
@import 'plugins/bootstrap-table';
@import 'plugins/bootbox';

@import 'plugins/amchart';
@import 'plugins/ckeditor';
@import 'plugins/clockface';
@import 'plugins/datatables';
@import 'plugins/dropzone';
@import 'plugins/fancybox';
@import 'plugins/fuelux';
@import 'plugins/fullcalendar';
@import 'plugins/gmaps';
@import 'plugins/gritter';
@import 'plugins/jquery-easy-pie-chart';
@import 'plugins/jquery-file-upload';
@import 'plugins/jquery-multi-select';
@import 'plugins/jquery-notific8';
@import 'plugins/jquery-pwstrength-bootstrap';
@import 'plugins/jquery-slimscroll';
@import 'plugins/jquery-sparkline';
@import 'plugins/jquery-ui';
@import 'plugins/jqvmap';
@import 'plugins/jstree';
@import 'plugins/nouislider';
@import 'plugins/recaptcha';
@import 'plugins/select2';
@import 'plugins/morris';
@import 'plugins/icheck';
@import 'plugins/ui-select';
@import 'plugins/minicolors';
@import 'plugins/codemirror';
@import 'plugins/prism';
@import 'plugins/socicon';